import { useEffect, useState } from "react";
import A4Meeting, { MeetingPollVoterType } from "@a4b/a4b-meeting";

const A4BMeetingSettings = "A4BMeetingSettings";
const resolutions = [144, 240, 360, 480, 720, 1080];

export default function App() {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState<number>(0);
	const [resolution, setResolution] = useState<number>(720);
	const [MeetingID, setMeetingID] = useState<string>("1");
	const [MeetingOwner, setMeetingOwner] = useState<boolean>(true);
	const [MeetingToken, setMeetingToken] = useState<string>("");
	const [MeetingName, setMeetingName] = useState<string>("Demonstração");
	const [MeetingDate, setMeetingDate] = useState<string>("2023-04-10T13:38:24.829Z");
	const [ParticipantID, setParticipantID] = useState<string>(`${Math.floor(Math.random() * (1000 - 1 + 1) + 1)}`);
	const [ParticipantName, setParticipantName] = useState<string>("");
	const [ChatEnabled, setChatEnabled] = useState<boolean>(true);
	const [LockEnable, setLockEnable] = useState<boolean>(true);
	const [PollsEnabled, setPollsEnabled] = useState<boolean>(true);
	const [medicalRecord, setMedicalRecord] = useState<boolean>(false);
	const [medicalRecordLink, setMedicalRecordLink] = useState<string | null>("");

	const [btnCopy, setBtnCopy] = useState("Copiar Link");
	const [btnOption, setBtnOptions] = useState<boolean>(false);

	const queryString = window.location.search;
	const url = new URLSearchParams(queryString);
	const autoAcess: boolean = url.get("a") === "1";
	const id = url.get("id");
	const token = url.get("token");
	const nome = url.get("nome");

	useEffect(() => {
		if (nome) {
			setMeetingName(nome);
		}

		let a4bMeetingSettings = localStorage.getItem(A4BMeetingSettings);
		let a4bMeetingSettingsJSON: any = {};
		if (a4bMeetingSettings !== null) {
			a4bMeetingSettingsJSON = JSON.parse(a4bMeetingSettings);
			if (a4bMeetingSettingsJSON.name) {
				setParticipantName(a4bMeetingSettingsJSON.name);
			}
		}

		if (autoAcess) {
			setMeetingID(id ?? "");
			setMeetingOwner(url.get("mo") === "1");
			setMeetingToken(token ?? "");
			setMeetingName(url.get("mn") ?? "");
			setParticipantID(url.get("pId") ?? "");
			setParticipantName(url.get("pn") ?? "");
			setChatEnabled(url.get("c") === "1");
			setLockEnable(url.get("l") === "1");
			setPollsEnabled(url.get("p") === "1");
			setMedicalRecord(url.get("mr") === "1");
			setMedicalRecordLink(url.get("mrl"));
			setStatus(3);
		}
	}, []);

	async function createMeeting() {
		setLoading(true);
		try {
			let response = await fetch("https://websocket.a4b.dev.br/meeting/create", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					type: "initially_not_defined_media",
					videoResolution: resolution,
					roomType: "call",
					anonymous: true,
					scheduledTo: "2022-12-31 18:00:00 +03:00",
					externalId: "1",
					webhook: {
						host: "api.a4bteste.dev.br/webhooks.a4bmeeting",
						authentication: "xxx",
					},
				}),
			});
			let responseJSON = await response.json();
			setMeetingID(responseJSON.meetingId);
			setMeetingToken(responseJSON.meetingToken);
			setStatus(1);
			setLoading(false);
		} catch (e) {
			console.log(e);
			alert("Problema ao criar sala, tente novamente mais tarde");
			setLoading(false);
		}
	}

	async function joinMeeting() {
		setMeetingOwner(false);
		setParticipantID("");
		setParticipantName("");
		setStatus(2);
	}

	async function joinToken() {
		setMeetingOwner(false);
		setMeetingID(id ?? "");
		setMeetingToken(token ?? "");
		setStatus(3);
	}

	async function startMeeting() {
		setLoading(true);
		let medicalReportLink = "";
		if (medicalRecord) {
			let response = prompt("Informe a senha");
			if (response !== "a4b") {
				alert("Senha inválida");
				setLoading(false);
				return;
			}
			medicalReportLink = await createMedicalReport();
		}
		window.location.href = encodeURI(
			`${window.location.href}?id=${MeetingID}&token=${MeetingToken}&a=1&mn=${MeetingName}&mo=${MeetingOwner ? 1 : 0}&pId=${ParticipantID}&pn=${ParticipantName}&c=${ChatEnabled ? 1 : 0}&l=${
				LockEnable ? 1 : 0
			}&p=${PollsEnabled ? 1 : 0}&mr=${medicalRecord ? 1 : 0}&mrl=${medicalReportLink}`
		);
		setLoading(false);
	}

	async function createMedicalReport() {
		try {
			let response = await fetch("https://emr-homolog.nexodata.com.br/api/prescricao/iniciar", {
				method: "POST",
				headers: { "Content-Type": "application/json", Authorization: "Basic YXBwNGJ1c2luZXNzOkRUcEhjRExVV1ZnRVlPa2JNeUZDWll6QQ==" },
				body: JSON.stringify({
					CorPrimaria: "#1E4D79",
					CorSecundaria: "#F0833A",
					LogoURL: "https://meeting.a4b.dev.br/images/logo.png",
					SubParceiro: "Clínica / Hospital / Nome do Cliente",
					Medico: {
						Nome: "Eduardo Médico",
						Documento: "04661522648",
						ReferenciaExterna: "ABC123",
						TelefoneCelular: "11998959898",
						Email: "eduardo+doutor@email.com.br",
						CNS: "CNS do médico",
						TipoDocumento: "CPF",
						Especialidades: ["Clínica Geral", "Cirurgia Geral"],
						RegistroProfissional: {
							Numero: "NX123456",
							Conselho: "CRM",
							UF: "SP",
						},
						Endereco: {
							Endereco1: "Rua Steven Gerrard",
							Endereco2: "8",
							Bairro: "Ídolo",
							Cidade: "São Paulo",
							Estado: "SP",
							CodigoPostal: "29142676",
						},
					},
					Paciente: {
						ReferenciaExterna: "ABC124",
						Nome: "Mohamed Salah",
						Nascimento: "06/03/1981",
						Sexo: "M",
						Etnia: "Branca | Não Informado | Preta | Parda | Amarela | Indígena ",
						Documento: "53631764081",
						TelefoneCelular: "11998959898",
						Email: "salah@liverpool.fc",
						Endereco: {
							Endereco1: "Rua dos Gols",
							Endereco2: "S/N",
							Bairro: "Anfield",
							Cidade: "Santos",
							Estado: "SP",
							CodigoPostal: "29142676",
						},
					},
					RegistroProntuarioEletronico: {
						ReferenciaExterna: "NUMERO-DO-ATENDIMENTO",
						TipoConsulta: "Atendimento Presencial | Teleconsulta | Alta Hospitalar",
					},
					Estabelecimento: {
						Nome: "Hospital das Américas",
						Logo: "https://www.pngfind.com/pngs/m/65-651964_hospital-png-transparent-png.png",
						Endereco: {
							Endereco1: "Avenida dos Hospitais, 86",
							Endereco2: "Ala B",
							Bairro: "Floridos",
							Cidade: "Búzios",
							Estado: "RJ",
							CodigoPostal: "29142676",
						},
					},
					ReferenciaExterna: "SEU-CODIGO-DA-RECEITA",
				}),
			});
			let responseJSON = await response.json();
			return responseJSON.ModalURL;
		} catch (e) {
			console.log(e);
			alert("Problema ao criar prontuário, tente novamente mais tarde");
			setLoading(false);
		}

		return false;
	}

	function copyLink() {
		let link = encodeURI(`${window.location.href}?id=${MeetingID}&token=${MeetingToken}&nome=${MeetingName}`);
		navigator.clipboard.writeText(link);
		setBtnCopy("Link Copiado");
	}

	function handleParticipantName(name: string) {
		setParticipantName(name);
		let a4bMeetingSettings = localStorage.getItem(A4BMeetingSettings);
		let a4bMeetingSettingsJSON = {};
		if (a4bMeetingSettings !== null) {
			a4bMeetingSettingsJSON = JSON.parse(a4bMeetingSettings);
		}
		a4bMeetingSettingsJSON = { ...a4bMeetingSettingsJSON, name: name };
		localStorage.setItem(A4BMeetingSettings, JSON.stringify(a4bMeetingSettingsJSON));
	}

	function handleCreateMeetingOptions(e: any) {
		e.preventDefault();
		e.stopPropagation();
		setBtnOptions(!btnOption);
	}

	function handleMedicalReport(status: boolean) {
		setMedicalRecord(status);
		setChatEnabled(true);
		setPollsEnabled(false);
		setMeetingOwner(true);
		setLockEnable(false);
	}

	return (
		<div className="container">
			{status !== 3 && (
				<div className="content row">
					<div className="col flex-fill jutify-content-between">
						<div className="row flex-fill">
							<div className="col jutify-content-center padding">
								<img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" style={{ width: "50%" }} />
								{status === 0 && (
									<>
										{token ? (
											<div className="col">
												<h3>{MeetingName}</h3>
												<input
													type="text"
													className="campo"
													placeholder="Digite aqui o seu nome"
													value={ParticipantName}
													onChange={(e) => {
														handleParticipantName(e.target.value);
													}}
												/>
												<button
													onClick={() => {
														joinToken();
													}}
													className="botao"
												>
													Entrar na Chamada
												</button>
											</div>
										) : (
											<div>
												<h1 className="title">Plataforma Video Conferência Online</h1>
												<div className="row gap flex-wrap">
													<div className="col">
														<div className="botao-grupo">
															<button
																onClick={() => {
																	createMeeting();
																}}
																className="botao blue"
																disabled={loading}
																style={{ flex: 1 }}
															>
																Criar Nova Chamada
															</button>
															<div
																onClick={handleCreateMeetingOptions}
																onBlur={() => {
																	setBtnOptions(false);
																}}
																className="botao blue"
															>
																&#9660;
																{btnOption && (
																	<div className="dropdown">
																		<ul>
																			{resolutions.map((item, index) => {
																				return (
																					<li
																						key={index}
																						className={`${item === resolution ? "active" : ""}`}
																						onClick={() => {
																							setResolution(item);
																						}}
																					>
																						Resolução {item}p
																					</li>
																				);
																			})}
																		</ul>
																	</div>
																)}
															</div>
														</div>
													</div>
													<div className="col">
														<button
															onClick={() => {
																joinMeeting();
															}}
															className="botao"
														>
															Entrar em uma Chamada
														</button>
													</div>
												</div>
											</div>
										)}
									</>
								)}
								{status === 1 && (
									<div className="col">
										<h3>Criar Nova Chamada</h3>
										<input
											type="text"
											className="campo"
											placeholder="Digite aqui o nome da chamada"
											value={MeetingName}
											onChange={(e) => {
												setMeetingName(e.target.value);
											}}
										/>
										<input
											type="text"
											className="campo"
											placeholder="Digite aqui o seu Nome"
											value={ParticipantName}
											onChange={(e) => {
												handleParticipantName(e.target.value);
											}}
										/>
										{!medicalRecord && (
											<>
												<div className="row gap flex-wrap">
													<label className="flex-fill campo-check text-nowrap">
														<input
															type="checkbox"
															placeholder="MeetingOwner"
															checked={MeetingOwner}
															onChange={(e) => {
																setMeetingOwner(e.target.checked);
															}}
														/>{" "}
														Apresentador
													</label>
													<label className="flex-fill campo-check text-nowrap">
														<input
															type="checkbox"
															placeholder="ChatEnabled"
															checked={ChatEnabled}
															onChange={(e) => {
																setChatEnabled(e.target.checked);
															}}
														/>{" "}
														Chat
													</label>
												</div>
												<div className="row gap flex-wrap">
													<label className="flex-fill campo-check text-nowrap">
														<input
															type="checkbox"
															placeholder="PollsEnabled"
															checked={PollsEnabled}
															onChange={(e) => {
																setPollsEnabled(e.target.checked);
															}}
														/>{" "}
														Votações
													</label>
													<label className="flex-fill campo-check text-nowrap">
														<input
															type="checkbox"
															placeholder="LockEnable"
															checked={LockEnable}
															onChange={(e) => {
																setLockEnable(e.target.checked);
															}}
														/>{" "}
														Bloqueios
													</label>
												</div>
											</>
										)}
										<div className="row gap flex-wrap">
											<label className="flex-fill campo-check text-nowrap">
												<input
													type="checkbox"
													placeholder="MedicalRecord"
													checked={medicalRecord}
													onChange={(e) => {
														handleMedicalReport(e.target.checked);
													}}
												/>{" "}
												Prontuário
											</label>
											<label className="flex-fill campo-check text-nowrap" style={{ border: 0, cursor: "default" }}></label>
										</div>
										<div className="row gap flex-wrap">
											<button
												onClick={() => {
													copyLink();
												}}
												className="botao flex-fill text-nowrap"
											>
												{btnCopy}
											</button>
											<button
												onClick={() => {
													startMeeting();
												}}
												className="botao blue flex-fill text-nowrap"
												disabled={loading}
											>
												Iniciar Meeting
											</button>
										</div>
										<button
											onClick={() => {
												setStatus(0);
											}}
											className="botao flex-fill"
											style={{ borderColor: "white" }}
										>
											Voltar
										</button>
									</div>
								)}
								{status === 2 && (
									<div className="col">
										<h3>Entrar em uma Chamada</h3>
										<input
											type="text"
											className="campo"
											placeholder="Digite aqui o token da chamada"
											value={MeetingToken}
											onChange={(e) => {
												setMeetingToken(e.target.value);
											}}
										/>
										<input
											type="text"
											className="campo"
											placeholder="Digite aqui o nome da chamada"
											value={MeetingName}
											onChange={(e) => {
												setMeetingName(e.target.value);
											}}
										/>
										<input
											type="text"
											className="campo"
											placeholder="Digite aqui o seu Nome"
											value={ParticipantName}
											onChange={(e) => {
												handleParticipantName(e.target.value);
											}}
										/>
										<div className="row gap flex-wrap">
											<label className="flex-fill campo-check">
												<input
													type="checkbox"
													placeholder="MeetingOwner"
													checked={MeetingOwner}
													onChange={(e) => {
														setMeetingOwner(e.target.checked);
													}}
												/>{" "}
												Apresentador
											</label>
											<label className="flex-fill campo-check">
												<input
													type="checkbox"
													placeholder="ChatEnabled"
													checked={ChatEnabled}
													onChange={(e) => {
														setChatEnabled(e.target.checked);
													}}
												/>{" "}
												Chat
											</label>
										</div>
										<div className="row gap flex-wrap">
											<label className="flex-fill campo-check">
												<input
													type="checkbox"
													placeholder="PollsEnabled"
													checked={PollsEnabled}
													onChange={(e) => {
														setPollsEnabled(e.target.checked);
													}}
												/>{" "}
												Votações
											</label>
											<label className="flex-fill campo-check">
												<input
													type="checkbox"
													placeholder="LockEnable"
													checked={LockEnable}
													onChange={(e) => {
														setLockEnable(e.target.checked);
													}}
												/>{" "}
												Bloqueios
											</label>
										</div>
										<div className="flex-wrap">
											<button
												onClick={() => {
													setStatus(3);
												}}
												className="botao blue"
											>
												Iniciar Meeting
											</button>
											<button
												onClick={() => {
													setStatus(0);
												}}
												className="botao flex-fill"
												style={{ borderColor: "white" }}
											>
												Voltar
											</button>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="row align-items-center padding">
							<div>
								<a href="https://apps4business.com.br/">
									<img src={process.env.PUBLIC_URL + "images/logo_apps.png"} alt="" style={{ width: "auto", height: 50, marginRight: 15 }} />
								</a>
							</div>
							<div>
								<a href="https://apps4business.com.br/" style={{ textDecoration: "none", color: "black", fontSize: 14 }}>
									Inovação para
									<br />
									Transformar a<br />
									Experiência Digital
								</a>
							</div>
						</div>
					</div>
					<div className="col flex-fill jutify-content-center">
						<img src={process.env.PUBLIC_URL + "images/background.png"} style={{ width: "100%" }} />
					</div>
				</div>
			)}
			{status === 3 && (
				<>
					{medicalRecord ? (
						<div className="row flex-fill" style={{ minWidth: 900 }}>
							<div className="flex-fill">
								<iframe src={`${medicalRecordLink}`} frameBorder={0} style={{ width: "100%", height: "100%" }} />
							</div>
							<div style={{ width: 400, borderLeftWidth: 1, borderLeftColor: "#AAA", borderLeftStyle: "solid" }}>
								<iframe src={window.location.href.replace("mr=1", "mr=0").replace("l=1", "l=0")} frameBorder={0} style={{ width: "100%", height: "100%" }} />
							</div>
						</div>
					) : (
						<A4Meeting
							MeetingID={MeetingID}
							MeetingOwner={MeetingOwner}
							MeetingToken={MeetingToken}
							MeetingName={MeetingName}
							MeetingDate={MeetingDate}
							ParticipantID={ParticipantID}
							ParticipantName={ParticipantName}
							ChatEnabled={ChatEnabled}
							LockEnable={LockEnable}
							PollsEnabled={PollsEnabled}
							PollsConfig={{
								voterTypes: false,
								voterTypeDefault: MeetingPollVoterType.simple,
							}}
							PIPEnable={true}
							EndBack={false}
							Config={{
								buttonCopyUrl: true,
							}}
							Video={{
								width: 426,
								height: 240,
							}}
							apiUrl="https://www.a4b.dev.br/vonmeeting"
							wsUrl="wss://websocket.a4b.dev.br/ws"
							turnUrl="turn:turn-hml.a4b.dev.br:3478"
							Theme={{
								background: "#ffffff",
								backgroundControl: "#f8f9fa",
								backgroundSidebar: "#f8f9fa",
								logo: {
									onWhite: process.env.PUBLIC_URL + "/images/logo.png",
									onBlack: process.env.PUBLIC_URL + "/images/logo.png",
								},
							}}
						/>
					)}
				</>
			)}
		</div>
	);
}
